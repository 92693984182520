<template>
  <div class="loginLog">
    <div style="width: 100%">
      <el-table
        :header-cell-style="{ background: '#F2F2F2' }"
        :data="tableData"
        border
        style="width: 100%; text-align: center"
      >
        logs/host.access.log main logs/error.log;
        <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
        <el-table-column align="center" width="200" label="时间">
          <template #default="scope">
            <div>
              {{
                new Date(scope.row.l_createtime * 1000).toLocaleDateString().split('/').join('-')
              }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" width="250" prop="l_ip" label="IP地址"></el-table-column>
        <el-table-column align="center" width="400" prop="l_area" label="地区"></el-table-column>
        <el-table-column align="center" prop="l_device" label="登陆设备"></el-table-column>
      </el-table>
      <div style="text-align: center; margin-top: 25px">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.page"
          :page-sizes="[10, 15, 30, 35]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.totalItems"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { login_log } from '../../common/setting';
export default {
  name: 'loginLog',
  data() {
    return {
      tableData: [''],
      page: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleSizeChange(size) {
      this.page.pageSize = size;
      this.init();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.init();
    },
    // 登陆日志
    init() {
      let dt = {
        page: this.page.page,
        pagesize: this.page.pageSize,
      };
      login_log(dt).then((res) => {
        this.tableData = res.result;
        this.page.totalItems = res.attach.count;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.loginLog {
  background-color: white;
  padding-bottom: 20px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #29303e;
}
</style>
