import $http from '@/utils/http';

// 登录日志
export function login_log(data) {
  return $http.post('/api/Setting/login_log', data, 'loadingDivS');
}

// 通知列表
export function getSystemNoticeList(data) {
  return $http.get('/api/notice/newest_list', data, 'loadingDivS');
}
